import React from "react";
import Layout from "../components/sitewide/layout";
import OpenBankingMap from "../components/observer/openBankingMap";

export default function Observer() {

  return <Layout>
    <h3
      className="text-primary text-xl font-black leading-normal md:leading-snug text-secondary">Shared data tracker</h3>
    <h1
      className="mb-4 text-primary text-4xl font-black leading-normal md:leading-snug">Country map</h1>
    <p>Explore what open banking, open finance and open data initiatives look like around the world.</p>
    <div
      className="m-auto md:inline-block md:w-1/4 md:p-0 md:align-top">
      <p className="text-base">Functionality:</p>
      <ul className="text-base">
        <li className="list-disc list-inside">Account data</li>
        <li className="list-disc list-inside">Payment initiation</li>
      </ul>
    </div><div
      className="m-auto md:inline-block md:w-1/4 md:p-0 md:align-top">
      <p className="text-base">Technical factors:</p>
      <ul className="text-base">
        <li className="list-disc list-inside">Open, dedicated APIs</li>
        <li className="list-disc list-inside">Financial-grade API security</li>
        <li className="list-disc list-inside">Automated client registrations</li>
      </ul>
    </div>al
    <div
      className="m-auto md:inline-block md:w-1/4 md:p-0 md:align-top">
      <p className="text-base">Extrinsic factors:</p>
      <ul className="text-base">
        <li className="list-disc list-inside">Realtime instant payments</li>
        <li className="list-disc list-inside">Data protection regulations</li>
        {/*<li className="list-disc list-inside">Governance framework</li>*/}
        <li className="list-disc list-inside">Trusted third party</li>
        <li className="list-disc list-inside">Bi-lateral agreements not required</li>
      </ul>
    </div>
    <div
      className="m-auto md:inline-block md:w-1/4 md:p-0 md:align-top">
      <p className="text-base">Supported products:</p>
      <ul className="text-base">
        <li className="list-disc list-inside">Transaction accounts</li>
        <li className="list-disc list-inside">Savings accounts</li>
        <li className="list-disc list-inside">Credit cards</li>
        <li className="list-disc list-inside">Loans</li>
      </ul>
    </div>
    <div className="m-auto">
      <OpenBankingMap/>
    </div>
  </Layout>;
  ;

}

import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {SunIcon, MoonIcon} from '/src/components/icons/dark-mode-toggle'
import Platern from "/src/assets/platern.svg"

export default function Header() {
    const [isExpanded, toggleExpansion] = useState(false);
    const [isDark, setDark] = useState(false)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (localStorage.theme) {
                setDark(localStorage.theme === 'dark')
            } else {
                setDark(window.matchMedia('(prefers-color-scheme: dark)').matches)
            }
            document.documentElement.classList.remove(isDark ? 'light' : 'dark')
            document.documentElement.classList.add(isDark ? 'dark' : 'light')
        }
    }, [isDark])

    const toggleDarkMode = () => {
        if (typeof window !== 'undefined') localStorage.theme = isDark ? 'light' : 'dark'
        setDark(!isDark)
    }

    // const Platern = require('../images/platern.svg')

    const menu = (
            <>
                <button
                    className="px-3 py-2 block text-secondary border border-black dark:border-white rounded md:hidden"
                    onClick={() => toggleExpansion(!isExpanded)}
                >
                    <svg
                        className="w-3 h-3 fill-current"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <title>Menu</title>
                        {isExpanded
                            ? <path d="M0 1a1 1 0 011-1h18a1 1 0 110 2H1a1 1 0 01-1-1zM2 7a1 1 0 011-1h14a1 1 0 110 2H3a1 1 0 01-1-1zM4 13a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1z"/>
                            : <path d="M0 1.25C0 .56.56 0 1.25 0h17.5a1.25 1.25 0 110 2.5H1.25C.56 2.5 0 1.94 0 1.25zM0 7.083c0-.69.56-1.25 1.25-1.25h17.5a1.25 1.25 0 110 2.5H1.25c-.69 0-1.25-.56-1.25-1.25zM0 12.917c0-.69.56-1.25 1.25-1.25h17.5a1.25 1.25 0 110 2.5H1.25c-.69 0-1.25-.56-1.25-1.25z"/> }
                    </svg>
                </button>

                <nav
                    className={`${
                        isExpanded ? `block` : `hidden`
                    } md:items-center md:hidden w-full md:w-auto absolute z-3 top-10 cursor-pointer`}
                >
                  <div className="flex flex-1 mt-4 no-underline text-center inline-block md:hidden -ml-40 md:mt-0 ml-auto md:ml-6">
                    <Link to="/direct-waitlist" className="text-secondary">Direct (waitlist)</Link>
                  </div>

                  <div
                        className="flex flex-1 mt-4 no-underline text-center inline-block md:hidden -ml-40 md:mt-0 ml-auto md:ml-6">
                        <button className="text-secondary" onClick={toggleDarkMode}>
                            {isDark ? `Light mode` : `Dark mode`}
                        </button>
                    </div>
                </nav>
                <div className="hidden md:block">
                  <Link className="text-primary align-middle text-base" to="/">Home</Link>
                </div>
                <div className="hidden md:block">
                  {/*<a className="text-primary align-middle text-base" href="http://localhost:3000">Documentation</a>*/}
                </div>
                <div className="hidden md:block">
                  <Link className="text-primary align-middle text-base" to="/direct-waitlist">Direct (waitlist)</Link>
                </div>
                <div className="hidden md:block">
                    <button className="md:inline-block md:align-middle -m-2 p-2 cursor-pointer" onClick={toggleDarkMode}>
                        {isDark ? <MoonIcon/> : <SunIcon/>}
                    </button>
                </div>
            </>
        )


    return (
        <header className="dark:bg-dark z-30 w-full">
            <div
                className="w-full items-center grid grid-flow-col justify-between max-w-6xl py-4 mx-auto px-8 md:p-8">
                    <Link to="/">
                        <div className="home inline-block left">
                            {/*<StaticImage*/}
                            {/*    src="../images/platern.svg"*/}
                            {/* alt="Platern"/>*/}
                            <Platern
                                className="header__logo dark:header__logo"
                                alt="Platern"
                            />
                        </div>
                    </Link>
                    <div className="flex flex-wrap space-x-4">
                      {menu}
                    </div>
            </div>
        </header>
    )
}

import * as d3 from "d3";
import "./map.css";
import data from "../../../data/ob-countries.json";
import React, {createRef, useRef, useState} from "react";
import Tooltip from "../misc/tooltip";

const projection = d3.geoEqualEarth();
const path = d3.geoPath(projection);
const outline = {type: "Sphere"};

// const data = fs.readFileSync("data/ob-countries.json").json()

// interface Highlight {
//   name: string,
//   field: string,
//   color: string,
// }
//
// const selectedHighlight: Highlight = {
//   name: "Live",
//   field: "isLive",
//   color: "#000",
// };

// adapted from https://observablehq.com/@mkfreeman/plot-tooltip
//tipMouseover(d3.select(this), tip)
// const onMouseMoveCountry = (d, e, tip) => {
//
//   setX();
//
//   const q0 = e[0] < width / 2 ? -1 : 1;
//   const q1 = e[1] < height / 2 ? -1 : 1;
//
//   const cs = getCountryStats(d);
//
//   tip.selectAll("*").remove();
//   tip.style("text-anchor", "middle")
//     .style("pointer-events", "none")
//     .selectAll("text")
//     .data(cs)
//     .join("text")
//     .attr("text-anchor", "middle")
//     .attr("font-weight", (d, i) => years.indexOf(year) === i - 1 ? "bold" : "normal")
//     .attr("dx", (d, i) => `0`)
//     .attr("dy", (d, i) => `${1.5 * i}em`)
//     .style("dominant-baseline", "ideographic")
//     .text((d) => d);
//
//   const bbox = tip.node().getBBox();
//
//   tip.append("rect")
//     .attr("x", bbox.x - tipMargins.h)
//     .attr("y", bbox.y - tipMargins.v)
//     .attr("rx", 5)
//     .attr("width", bbox.width + tipMargins.h * 2)
//     .attr("height", bbox.height + tipMargins.v * 2)
//     .style("fill", "white")
//     .style("stroke", "#d3d3d377")
//     .style("stroke-width", "2")
//     .lower();
//
//   tip.attr("transform", `translate(${e[0] + (e[0] > width / 2 ? -50 : 50)}, ${e[1] + (e[1] > height / 2 ? -bbox.height : 50)})`);
//
// };

export default function OpenBankingMap() {

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const globeRef = useRef(null);

  const onMouseMoveCountry = (d, e) => {
    // console.log(`mouse: (${e.clientX}, ${e.clientY})`)
    setX(e.clientX);
    setY(e.clientY);
  };

  return (
    <svg
      width="100%"
      height="500"
      className="observer"
      style={{display: "block"}}>
      <g ref={globeRef}>
      <path d={path(outline)} className="globe dark:globe">
      </path>
      {data.map((d) => (
          <>
            <path key={d}
                  d={path(d)}
                  onMouseMove={e => onMouseMoveCountry(d, e)}
                  className={d.isLive ? "land-highlight dark:land-highlight" : "land-nohighlight dark:land-nohighlight"}></path>
            <Tooltip d={d} x={x} y={y} boundElem={globeRef} bbox={globeRef?.current?.getBoundingClientRect()}/>
          </>
        ),
      )}
      </g>
    </svg>);

}



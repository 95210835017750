import React, {RefObject} from "react";
import "./tooltip.css";
import * as util from "util";

interface IProps {
  className?: string
  d: any
  x: number
  y: number
  boundElem: RefObject<any>
  // bbox: SVGRect
}

const Tooltip = ({...props}: IProps) => {
  // console.log(typeof props.boundElem.current)
  if(props.boundElem.current === null) return <></>
  const bbox = props.boundElem.current.getBoundingClientRect()
  // console.log(`props.boundElem.current.bbox: (${bbox.x}, ${bbox.y})`)

  const relativeX = props.x - bbox.left
  const relativeY = props.y - bbox.top

  // console.log(`relative coords: (${relativeX}, ${relativeY})`)

  // console.log(`d: ${util.inspect(props.d)}`)
  return (
    <g transform={`translate(${relativeX}, ${relativeY})`}>
      <rect className={"tooltip-bg dark:tooltip-bg"} width={500} height={100} />
      <text fill={'white'} transform={`translate(0, 60)`}>{props.d}</text>
    </g>
  )

}

export default Tooltip
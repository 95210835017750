import React from "react"

export function SunIcon() {
    return (
        <svg width="18" height="18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#121212"
                d="M9 3.155a.5.5 0 00.5-.5v-1.7a.5.5 0 00-1 0v1.7a.5.5 0 00.5.5zM9 14.845a.5.5 0 00-.5.5v1.7a.5.5 0 001 0v-1.7a.5.5 0 00-.5-.5zM4.16 4.871a.5.5 0 00.34.13.5.5 0 00.355-.855l-1.19-1.185a.5.5 0 00-.705.705l1.2 1.205zM13.84 13.13a.502.502 0 00-.71.71l1.205 1.2a.498.498 0 00.355.15.5.5 0 00.35-.15.5.5 0 000-.705l-1.2-1.205zM3.155 9.001a.5.5 0 00-.5-.5h-1.7a.5.5 0 100 1h1.7a.5.5 0 00.5-.5zM17.045 8.501h-1.7a.5.5 0 100 1h1.7a.5.5 0 000-1zM4.16 13.13l-1.2 1.205a.5.5 0 00.35.855.498.498 0 00.355-.15l1.205-1.2a.502.502 0 10-.71-.71zM13.5 5a.5.5 0 00.355-.145l1.2-1.205a.5.5 0 00-.352-.85.5.5 0 00-.353.145l-1.205 1.2A.5.5 0 0013.5 5z"/>
            <path
                fill="#121212"
                d="M9.065 3.874a5.065 5.065 0 105 5.065 5.04 5.04 0 00-5-5.065zm0 9.125a4.065 4.065 0 114-4.06 4.04 4.04 0 01-4 4.06z"/>
        </svg>
    )
}

export function MoonIcon() {
    return (
        <svg width="16" height="16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.358 14.706A7.357 7.357 0 015.6.49c-.129 1.54-.135 2.932.039 4.134.193 1.337.614 2.468 1.367 3.305 1.461 1.621 4.01 1.98 7.946.997a7.359 7.359 0 01-5.594 5.781z"
                stroke="#FCFCFC" strokeWidth=".736"/>
        </svg>
    )
}
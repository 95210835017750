import LinkedIn from "./linkedin";
import Twitter from "./twitter";
import Facebook from "./facebook";
import GitHub from "./github";
import React from "react";

export default function SocialBar() {
  return (
    <div className="flex flex-wrap inline-block w-1/3 justify-around text-center mb-8 md:mb-16 m-auto">
      <div>
        <a href="https://www.linkedin.com/company/68859204">
          <LinkedIn className=""/>
        </a>
      </div>
      <div>
        <a href="https://twitter.com/platernweb">
          <Twitter className=""/>
        </a>
      </div>
      <div>
        <a href="https://www.facebook.com/platern">
          <Facebook className=""/>
        </a>
      </div>
      <div>
        <a href="https://www.github.com/platern">
          <GitHub className=""/>
        </a>
      </div>
    </div>
  );
}
import React from "react"
import {graphql, Link, StaticQuery} from "gatsby";
import SocialBar from "../social/social-bar";

const FooterTemplate = ({legalLinks}) => (

    <footer className="dark:bg-dark bg-mainbg pt-12 md:pt-0">
        <nav
            className="text-center md:justify-between md:flex md:grid-cols-4 max-w-6xl p-4 mx-auto text-base md:p-8">
            {legalLinks}
        </nav>
    </footer>
)

export default function Footer(props) {

    return (
        <StaticQuery query={
            graphql`
query {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "\/(\\\/legal)\\\/.*(.)md$\/"}}) {
    edges {
      node {
        id
        frontmatter {
          title
          slug
        }
      }
    }
  }
}
`} render={data => {
            const legalLinks = (
                <div className="w-full">
                  <section>
                    <SocialBar />
                  </section>
                    {
                        data.allMarkdownRemark.edges.map((edge) => {
                            return <div className="md:px-8 md:inline-block"
                                        key={`footer-` + edge.node.frontmatter.slug}>
                                <Link className="primary hover:underline"
                                      style={{transition: 'all 0.3s ease'}}
                                      to={edge.node.frontmatter.slug}>{edge.node.frontmatter.title}</Link>
                            </div>
                        })
                    }
                </div>
            )
            return <FooterTemplate legalLinks={legalLinks} {...props}/>
        }}/>
    )
}